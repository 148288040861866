import { useParams } from "react-router-dom"
import { useAuth } from "react-oidc-context";
import { useOrganizationStore } from '../stores/OrganizationStore';
import { OrganizationAPI, OrganismAPI, PipelineAPI } from '../api/DataAPI';
import { useEffect, useState } from "react";
import { Spinner } from "@blueprintjs/core/lib/esm/components/spinner/spinner"
import { NavLink } from "react-router-dom";
import { Navbar } from "@blueprintjs/core/lib/esm/components/navbar/navbar";
import { NavbarGroup } from "@blueprintjs/core/lib/esm/components/navbar/navbarGroup";
import { NonIdealState } from "@blueprintjs/core/lib/esm/components/non-ideal-state/nonIdealState";
import { UserProfilePopover } from "./UserProfilePopover";
import { EventNotificationPopover } from "./EventNotificationPopover";
import { Button, Divider, H2, H3, H5, Menu, MenuItem, Callout, Icon, IconName } from "@blueprintjs/core";
import { useAppStore } from "../stores/AppStore";
import { useOrganismStore } from '../stores/OrganismStore';
import PipelineTemplatesDialog from "./PipelineTemplatesDialog";
import { RunPipelineDialog } from "./startPipelineDialog/StartPipelineDialog";
import { PipelineRunsDrawer } from "./PipelineRunsDrawer";
import { PipelineRunsDialog } from "./PipelineRunsDialog";
import { Toaster } from "../utils/Toaster"
import { Organism } from "../types/OrganismTypes";
import { useStartPipelineDialogStore } from "../stores/StartPipelineDialogStore";
import { usePipelinesStore } from "../stores/PipelinesStore";
import { handleMenuItemKeyPress } from "../services/508Accessibility";
import { Popover2 } from "@blueprintjs/popover2";
import { object } from "dot-object";
import { useUserStore } from "../stores/UserStore";
// import { useDataViewerCtrFunctions } from "../hooks/useDataViewerCtrFunctions";

export function LandingPageSelection() {

  const auth = useAuth()
  const { user } = useUserStore()
  const isReadOnly = user?.role.includes("ReadOnly")
  const { setOrganism, setOrganismList, organismList } = useOrganismStore()
  const { organizationName } = useParams()
  const { organization, setOrganization } = useOrganizationStore()
  const { setShowStartPipelineDialog, setShowPipelineRunsDrawer, setSelectedViewId, setShowStartCalcPipelineDialog } = useAppStore()
  const { filesUploading, linkingFiles } = useStartPipelineDialogStore()
  const { setPipelines } = usePipelinesStore()
  const organizationAPI = new OrganizationAPI(auth.user?.access_token ?? "")
  const organismAPI = new OrganismAPI(auth.user?.access_token ?? "")
  const pipelineAPI = new PipelineAPI(auth.user?.access_token ?? "")


  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const orgColorMap: Record<string, string> = {
    'pulsenet': '#eecc77',
    'calicinet': '#9ACEFF'
  }

  async function setOrganismsFor(organization: string) {
    try {

      const organisms = await organismAPI.getAll() as unknown as string[];
      const filteredOrganisms = organisms.map(v => JSON.parse(v)).filter((organism: Organism) => {
        if (organism.organization === organization) {
          return organism
        }
      })
      setOrganismList(filteredOrganisms)
      setLoading(false)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setError(true)
      Toaster.show({ icon: "error", message: `API Error: ${error}`, intent: "danger" })
    }
  }

  async function getCurrentOrganization() {
    try {
      setLoading(true)
      if (!organizationName)
        throw new Error("No organization found.")
      const organization = await organizationAPI.getOrganizationByName(organizationName);
      setOrganization(organization)
      setOrganismsFor(organization.organizationName)
      const pipelines = await pipelineAPI.getPipelines(organization.organizationName)
      setPipelines(pipelines)
    } catch (error) {
      setError(true)
      setLoading(false)
      // eslint-disable-next-line no-console
      console.error(error)
      Toaster.show({ icon: "error", message: `API Error: ${error}`, intent: "danger" })

    }
  }

  useEffect(() => {
    if (organization){
      setLoading(true)
      setOrganismsFor(organization?.organizationName)      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization])

  useEffect(() => {
    setSelectedViewId(undefined)
    if (!organization || organization.organizationName.toLowerCase() !== organizationName || !organismList) getCurrentOrganization()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='grid-header'>
      <Navbar style={{ backgroundColor: Object.entries(orgColorMap).find(([k]) => organizationName?.toLowerCase().includes(k))?.[1] || '#eecc77' }}>
        <NavbarGroup align={'left'}>
          <NavLink style={{ textDecoration: 'none', color: 'black', outline: 'none' }} to="/"><Button icon="home" minimal title="Site Homepage"></Button></NavLink>
          <Divider style={{ height: '50%', margin: '10px' }} />
          {!loading && organization ?

            <div className="bp4-navbar-heading" style={{ padding: "5px 10px" }}>{organization?.organizationName}</div>

            :
            !error ?
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Spinner style={{ marginRight: '5px' }} size={25} />
              </div>
              :
              null
          }
          <div className="bp4-navbar-heading">{window.parsedConfig.DEPLOY_ENVIRONMENT.toUpperCase()} {window.parsedConfig.DEPLOY_VERSION.toUpperCase()}</div>
        </NavbarGroup>
        <NavbarGroup align={'right'}>
          {(filesUploading || linkingFiles) && <Spinner size={15} title="File linking in progress."></Spinner>}
          {!isReadOnly && <Popover2
            minimal
            placement="bottom-start"
            content={
              <Menu>
                <MenuItem aria-label="link files" icon="link" text="Link Files" onKeyDown={(event) => handleMenuItemKeyPress(event, () => setShowStartPipelineDialog(true))} onClick={() => {
                  organizationName?.toLowerCase().includes('calicinet') ? setShowStartCalcPipelineDialog(true) : setShowStartPipelineDialog(true)
                }} />
                <MenuItem icon="folder-open" text="View All Pipeline Runs" onKeyDown={(event) => handleMenuItemKeyPress(event, () => setShowPipelineRunsDrawer(true))} onClick={() => setShowPipelineRunsDrawer(true)} />
              </Menu >
            }
            interactionKind="click"
          >
            <Button id="top-header" text={`Pipelines`} icon="flows" minimal />
          </Popover2 >}
          <EventNotificationPopover />
          <UserProfilePopover />
        </NavbarGroup >
      </Navbar>
      {
        loading ?
          <div style={{ position: 'absolute', top: "20%", left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spinner size={55}></Spinner>
            <h2>Loading Organism Databases...</h2>
          </div>
          :
          !error ?
            <div style={{ marginTop: '25px', marginLeft: '25px', display: "flex" }}>
              <div style={{}}>
                <H2>Select an Organism Database</H2>
                {
                  organization &&
                  organismList?.map((organism) => {
                    return (
                      <NavLink key={organism.id} to={`/${organizationName}/${organism.name.toLowerCase()}`}>
                        <Button key={organism.id} style={{ height: '140px', width: '200px', margin: '20px' }} large={true} onClick={() => {
                          setOrganism(organism)
                        }}>{organism.name}</Button>
                      </NavLink>
                    )
                  })
                }
              </div>
              {
                organization?.alerts && (
                  <div style={{maxWidth: "33vw", height:"70vh", minHeight:0, overflowY: "auto", overflowX: "hidden", marginRight:15, paddingRight: 15}}>
                    {
                      organization.alerts.map((alert, index) => {
                        return (
                          <Callout key={index} icon={alert.icon as IconName} style={{margin:"8px"}} intent={alert.intent ?? "none"}>
                            <div style={{display: "flex", alignItems: "end", marginTop: "4px"}}>                              
                              <H5 style={{marginLeft: "6px"}}>{alert.title}</H5>
                            </div>
                            <div style={{marginTop: "4px"}}>
                              <p>
                                {alert.content}
                              </p>
                              <em>
                                {alert.createdAt}
                              </em>
                            </div>
                          </Callout>
                        )
                      }).reverse()
                    }
                  </div>
                )
              }
            </div>
            :
            <div style={{ position: 'absolute', top: "20%", left: '50%', transform: 'translate(-50%, -50%)' }}>
              <NonIdealState
                icon={"error"}
                title={"There seems to be an issue..."}
                description={"That organization either doesn't exist or there was an error fetching.."}
              />
            </div>
      }
      <PipelineTemplatesDialog />
      <RunPipelineDialog />
      <PipelineRunsDrawer />
      <PipelineRunsDialog />
    </div>
  )
}