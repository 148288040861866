import * as yup from "yup";
import jsonLogic from 'json-logic-js';
import { SampleAPI } from "../api/DataAPI";
import { Sample } from "../types/SampleTypes";
import { FieldProps, Fields, Organism } from "../types/OrganismTypes";

export function GetSamplesYupSchema(values: any, organism: Organism | undefined, labId: string, importLevel?: string, newItem?: boolean, token?: string, samples?: Sample[], submitting?: React.MutableRefObject<boolean>) {
  const organization = organism?.organization
  const organismFields: Fields = { required: importLevel === "results" ? [] : (organism?.required || []), properties: Object.fromEntries(Object.entries(organism?.properties!).filter(([k, v]) => (!v.level || v.level === "Specimen"))) }

  if (organization?.includes('PulseNet') && organism) {
    const fieldsEntries = Object.entries(organism.properties)
    const objSchemaEntries = fieldsEntries.map(([k, v]) => {
      let yupFieldSchema: any
      yupFieldSchema = TypeValidations(v)
      if (organism.required.includes(k))
        yupFieldSchema = yupFieldSchema.required(`${k} is required.`)

      if (k === "PatientSex") {
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).when('SourceType', {
          is: 'Human',
          then: yup.string().required('Patient Sex is required'),
          otherwise: yup.string().nullable(),
        })
      }

      yupFieldSchema = CommonValidations(yupFieldSchema, values, k, v, organization, organismFields, organism)

      if (k === "Key") {
        yupFieldSchema = (yupFieldSchema as yup.StringSchema)
          .test("no-trailing-spaces", "Key cannot have trailing spaces.", (value) => {
            return value ? value.trim() === value : true;
          })
        // if (newItem && token)
        //   yupFieldSchema = (yupFieldSchema as yup.StringSchema)
        //     .test("checkDuplKey", "Key value exists in lab database.", async (value) => {
        //       const sampleAPI = new SampleAPI(token)
        //       let duplicate = false

        //       if (value){//Case-insensitive duplicate check
        //           const lowercaseKey = value.toLowerCase();
        //           let isDuplicateKey = false;

        //           if (samples?.length) {
        //             isDuplicateKey = samples.some(samples => samples.identifier.toLowerCase() === lowercaseKey);
        //           }

        //           //call the api after case-insensitive duplicate check is passed
        //           if (!isDuplicateKey && (submitting? submitting.current : true)) {
        //             try {
        //               await sampleAPI.getByKey(value)
        //               duplicate = true
        //             }
        //             catch (e: any) {
        //               if (e.message && JSON.parse(e.message).status !== 404) {
        //                 throw e
        //               }
        //               else
        //                 duplicate = false
        //             }                  
        //           }

        //         }                
        //       return !duplicate
        //     })
        //     .test("checkNDBDuplKey", "Key value exists in national database.", async (value) => {
        //       const sampleAPI = new SampleAPI(token)
        //       let duplicate = false

        //       if (value) {               
        //           //Case-insensitive duplicate check
        //           const lowercaseKey = value.toLowerCase();
        //           let isDuplicateKey = false;

        //           if (samples?.length) {
        //             isDuplicateKey = samples.some(samples => samples.identifier.toLowerCase() === lowercaseKey);
        //           }

        //           //call the api after case-insensitive duplicate check is passed
        //           if (!isDuplicateKey &&  (submitting? submitting.current : true)) {
        //             try {
        //               await sampleAPI.getNdbByKey(`${labId.padEnd(5, "_")}${value}`)
        //               duplicate = true
        //             }
        //             catch (e: any) {
        //               if (e.message && e.message !== "Failed to get ndb sample by identifier.")
        //                 throw e
        //               else
        //                 duplicate = false
        //             }
        //           }
        //         }
        //       return !duplicate
        //     })
      }

      return [k, yupFieldSchema]
    })
    const objSchema = Object.fromEntries(objSchemaEntries)

    // const yupRequired = Object.fromEntries(pulsenetFields.required.map(v => [v, yup.string().required(`${v} is required.`)]))
    // const yupMin = Object.entries(pulsenetFields.properties).filter(([k,v]) => v.min && v.type === "number").map(([k,v]) => [v, yup.number().min(v.min!, `The minimum value allowed for ${k} is ${v.min}.`)])
    // const yupMax = Object.entries(pulsenetFields.properties).filter(([k,v]) => v.max && v.type === "number").map(([k,v]) => [v, yup.number().max(v.max!, `The minimum value allowed for ${k} is ${v.max}.`)])
    return yup.object().shape(objSchema).required();

  } else if (organization?.toLowerCase().includes('calicinet') && organism) {
    const fieldsEntries = Object.entries(organismFields.properties)
    const objSchemaEntries = fieldsEntries.map(([k, v]) => {
      let yupFieldSchema: any
      switch (v.type) {
        case "number": yupFieldSchema = yup.number(); break;
        case "numbernull": yupFieldSchema = yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null); break;
        case "date":
          if (k === "OnsetDate" && importLevel !== "results") {
            yupFieldSchema = yup.mixed().nullable(true).transform((_, val) => {
              if (val === "" || val === null) return null;
              if (typeof val === 'string' && (val.trim().toLowerCase() === "unknown" || val.trim().toLowerCase() === "not provided")) return val.trim().toLowerCase();
              return new Date(val);
            }).test(
              "onset-date-format",
              "OnsetDate must be a valid date in 'yyyy-mm-dd' format, or 'Unknown' or 'Not Provided'.",
              (value) => {
                if (typeof value === 'string' && (value.trim().toLowerCase() === "unknown" || value.trim().toLowerCase() === "not provided")) return true;
                if (value === null) return true;
                return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true;
              }
            );
          } else if (importLevel !== "results") {
            if (v.allowUnknown)
              yupFieldSchema = yup.mixed().nullable(true).transform((_, val) => {
                if (val === "" || val === null) return null;
                if (typeof val === 'string' && val.trim().toLowerCase() === "unknown") return val.trim().toLowerCase();
                return new Date(val);
              }).test(
                "allownUnknown-format",
                `${k} must be a valid date in 'yyyy-mm-dd' format, or 'Unknown'.`,
                (value) => {
                  if (typeof value === 'string' && value.trim().toLowerCase() === "unknown") return true;
                  if (value === null) return true;
                  return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true;
                }
              );
            else
              yupFieldSchema = yup.date().nullable(true).transform((_, v) => (v === "" || v === null) ? null : new Date(v));
          }
          break;
        default: yupFieldSchema = yup.string(); break;
      }

      if (v.type === "enumroundednumber") {
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum-or-number", `${k} value must be either 'valid enum value' or 'number'.`, (value) => {
          if (value === undefined) {
            return !organismFields.required.includes(k)
          }

          // Check if the value is one of the enum values
          const isEnumValid = typeof value === "string" && (v.enum!.includes(value) || v.enum!.some(x => x.toLowerCase().startsWith(value.trim())))

          // Check if the value is number
          const isNumber = typeof value === "string" && !isNaN(value as any) && Number.isFinite(Number(value))

          return isEnumValid || isNumber
        })
      }

      if (organismFields.required.includes(k)) {
        // The field is required. If it's "OnsetDate" or "CollectionDate", it can be null.
        if (k === "OnsetDate" || k === "CollectionDate") {
          yupFieldSchema = yupFieldSchema.nullable(true)
        } else {
          yupFieldSchema = yupFieldSchema.required(`${k} is required.`)
        }
      }

      yupFieldSchema = importLevel === "results" ? CommonValidationsForResults(yupFieldSchema, values, k, v, organization, organismFields, organism) : CommonValidations(yupFieldSchema, values, k, v, organization, organismFields, organism)

      return [k, yupFieldSchema]
    })
    const objSchema = Object.fromEntries(objSchemaEntries)

    return yup.object().shape(objSchema).required();
  }
}

function TypeValidations(v: FieldProps) {
  let yupFieldSchema: any
  switch (v.type) {
    case "number": yupFieldSchema = yup.number(); break;
    case "numbernull": yupFieldSchema = yup.number().nullable(true); break;
    case "text": yupFieldSchema = yup.string(); break;
    case "date": yupFieldSchema = yup.date().nullable(); break;
    default: yupFieldSchema = yup.string(); break;
  }

  return yupFieldSchema;
}

function CommonValidations(yupFieldSchema: any, values: any, k: string, v: FieldProps, organization: string, organismFields: Fields, organism: Organism) {
  if (v.max && yupFieldSchema.max) {
    const [max, message] = typeof (v.max) === "string" && v.max.startsWith("$")
      ? (v.max === "$today" && v.type === "date")
        ? [new Date(), `Future dates are not valid.`]
        : [yup.ref(v.max.substring(1)), `${k} cannot be after ${v.max.substring(1)}`]
      : [v.max, `The maximum value allowed for ${k} is ${v.max}.`]
    yupFieldSchema = yupFieldSchema.max(max, message).nullable(true)
  }

  if (v.min && yupFieldSchema.min) {
    const [min, message] = typeof (v.min) === "string" && v.min.startsWith("$")
      ? (v.min === "$today" && v.type === "date")
        ? [new Date(), `Past dates are not valid.`]
        : [yup.ref(v.min.substring(1)), `${k} cannot be before ${v.min.substring(1)}`]
      : [v.min, `The minimum value allowed for ${k} is ${v.min}.`]
    yupFieldSchema = yupFieldSchema.min(min, message).nullable(true)
  }

  if (v.invalidIf) {
    //data format standardization
    if (v.invalidIf.message?.startsWith('standardization')) {
      const valuesWithEnum = {
        ...values,
        enum: v.enum
      }
      var before = valuesWithEnum[k]
      var matchedEnum;

      if (v.invalidIf.message.split("-")?.[1] === "standardizeEnums" && before) {
        matchedEnum = v.enum!.find(e => {
          const parts = e.toLowerCase().split('-');
          return parts.includes(before.toLowerCase()) || e.toLowerCase() === before.toLowerCase();
        });
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizePatientSex") {
        if (before && before !== 'MALE' && (before.toLowerCase() === 'm' || before.toLowerCase() === 'male')) {
          matchedEnum = 'MALE';
        } else if (before && before !== 'FEMALE' && (before.toLowerCase() === 'f' || before.toLowerCase() === 'female')) {
          matchedEnum = 'FEMALE';
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "roundNumber") {
        if (!isNaN(Number(before)) && Number.isFinite(Number(before)) && Math.round(before) !== Number(before)) {
          matchedEnum = Math.round(before)
        } else if (before && before.toLowerCase() === 'pos') {
          matchedEnum = "Positive"
        } else if (before && before.toLowerCase() === 'neg') {
          matchedEnum = "Negative"
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "blankToUnknown") {
        matchedEnum = "Unknown"
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizeDashes") {
        if (before && before.toLowerCase().includes("sporadic -"))
          matchedEnum = before.replace("-", "–")
      }

      var after = matchedEnum ? matchedEnum : ""
      var beforeAfterMessage;
      if (after !== "") {
        beforeAfterMessage = `standardization before:${before} -> after:${after}`
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("standardization-error", beforeAfterMessage, () => !jsonLogic.apply(v.invalidIf!.rule, valuesWithEnum))
      } else {
        if (v.enum && !v.freeEntry)
          yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
            return value ? v.enum!.includes(value) : (organization.includes('PulseNet') ? !organism.required.includes(k) : !organismFields.required.includes(k))
          })
      }
    } else
      yupFieldSchema = (yupFieldSchema as yup.NumberSchema).test("invalid-if", v.invalidIf.message, () => !jsonLogic.apply(v.invalidIf!.rule, values))
  }

  if (!v.allowUnknown && v.pattern)
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("pattern", `${k} must be in ${v.examples ? v.examples[0] : ""} format`, (value) => {
      return values[k] ? (new RegExp(v.pattern!)).test(values[k] ?? "") : true
    })

  if (v.enum && !v.freeEntry) {
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
      if (v.invalidIf && v.invalidIf.message?.startsWith('standardization')) {
        return true
      }

      // Non-required fields: allow null or if a value is present, it must be included in the enum
      if (!organism.required.includes(k)) {
        return value === undefined || value === "" || (typeof value === "string" && v.enum!.includes(value))
      }

      // Required fields: value must be a string and included in the enum
      return value
        ? v.enum!.includes(value)
        : (organization.includes('PulseNet') ? !organism.required.includes(k) : !organismFields.required.includes(k));
    })
  }


  return yupFieldSchema
}

function CommonValidationsForResults(yupFieldSchema: any, values: any, k: string, v: FieldProps, organization: string, organismFields: Fields, organism: Organism) {
  if (["OnsetDate", "CollectionDate"].includes(k)) return null  
  if (v.invalidIf) {
    //data format standardization
    if (v.invalidIf.message?.startsWith('standardization')) {
      const valuesWithEnum = {
        ...values,
        enum: v.enum
      }
      var before = valuesWithEnum[k]
      var matchedEnum;

      if (v.invalidIf.message.split("-")?.[1] === "standardizeEnums" && before) {
        matchedEnum = v.enum!.find(e => {
          const parts = e.toLowerCase().split('-');
          return parts.includes(before.toLowerCase()) || e.toLowerCase() === before.toLowerCase();
        });
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizePatientSex") {
        if (before && before !== 'MALE' && (before.toLowerCase() === 'm' || before.toLowerCase() === 'male')) {
          matchedEnum = 'MALE';
        } else if (before && before !== 'FEMALE' && (before.toLowerCase() === 'f' || before.toLowerCase() === 'female')) {
          matchedEnum = 'FEMALE';
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "roundNumber") {
        if (!isNaN(Number(before)) && Number.isFinite(Number(before)) && Math.round(before) !== Number(before)) {
          matchedEnum = Math.round(before)
        } else if (before && before.toLowerCase() === 'pos') {
          matchedEnum = "Positive"
        } else if (before && before.toLowerCase() === 'neg') {
          matchedEnum = "Negative"
        }
      } else if (v.invalidIf.message.split("-")?.[1] === "blankToUnknown") {
        matchedEnum = "Unknown"
      } else if (v.invalidIf.message.split("-")?.[1] === "standardizeDashes") {
        if (before && before.toLowerCase().includes("sporadic -"))
          matchedEnum = before.replace("-", "–")
      }

      var after = matchedEnum !== undefined ? matchedEnum : ""
      var beforeAfterMessage;
      if (after !== "") {
        beforeAfterMessage = `standardization before:${before} -> after:${after}`
        yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("standardization-error", beforeAfterMessage, () => !jsonLogic.apply(v.invalidIf!.rule, valuesWithEnum))
      } else {
        if (v.enum && !v.freeEntry)
          yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
            return value ? v.enum!.includes(value) : (organization.includes('PulseNet') ? !organism.required.includes(k) : !organismFields.required.includes(k))
          })
      }
    } else
      yupFieldSchema = (yupFieldSchema as yup.NumberSchema).test("invalid-if", v.invalidIf.message, () => !jsonLogic.apply(v.invalidIf!.rule, values))
  }

  if (v.enum && !v.freeEntry) {
    yupFieldSchema = (yupFieldSchema as yup.StringSchema).test("enum", `${k} value is an invalid enum value.`, (value) => {
      if (v.invalidIf && v.invalidIf.message?.startsWith('standardization')) {
        return true
      }
      // Required fields: value must be a string and included in the enum
      return value
        ? v.enum!.includes(value)
        : (organization.includes('PulseNet') ? !organism.required.includes(k) : !organismFields.required.includes(k));
    })
  }
  return yupFieldSchema
}