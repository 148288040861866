import { create } from 'zustand'
import { ComparedSample, Comparison, ComparisonRegion, ComparisonWithAnalysis, ComparisonWithDendrogram_tag, MultipleAlignmentData, TagsData } from '../types/ComparisonTypes';
import { Sample, SampleMetaDataDetailListItem } from '../types/SampleTypes';
import { Sort, SortedColumn } from '../components/DataViewerCtr';
import { DendrogramAlgorythm, DendrogramDistance, MLTreeRunMode as MLTreeRunMode, MultipleAlignment, ProcesstypeList, SequenceType, ViewDisplayed } from './ComparisonViewStore';
import { CellEdits } from '../components/EditDataViewer';
import { tagPositonX } from '../components/DisplayDendrogram';
import { useDataViewerCtrStore } from './DataViewerCtrStore';

interface ComparisonTab {
  name: string
  id: string //randomly assigned guid to track tab
  unsaved: boolean
  comparison: Comparison
  //toolbar
  searchComparisonText: string
  viewDisplayed: ViewDisplayed
  analysesDisplayed: string
  //analyses
  analysesData?: ComparisonWithAnalysis
  allProcessTypes: string[]
  //dendrogram
  tagsData?: ComparisonWithDendrogram_tag[]
  dendrogramRegions?: ComparisonRegion[]
  selectedIdsDendrogram?: string[]
  tagPositions?: tagPositonX[]
  selectedRegion?: ComparisonRegion | undefined
  previousSelectedRegionID?: 'root' | number | undefined
  maxTreeHeight?: number
  collapeseZeroLengthNodes: boolean
  sequenceType?: SequenceType 
  isFastMode?: boolean
  //multipleAlignment
  multipleAlignment?: MultipleAlignment
  multipleAlignmentData?: MultipleAlignmentData
  processType: ProcesstypeList
  blockType: 'diff' | null

  //comparison view
  sortedColumn?: SortedColumn
  sort: Sort
  isSortedInDendrogram: boolean
  sampleData?: Sample[]
  filteredSortedSampleIds?: string[]
  editDataViewerKey: number
  newEdits: string[]
  showSingleClickEdit: boolean
  cellEdits: CellEdits
  unselectafterdelete: boolean

  //loading
  samplesLoading: boolean
  analysesLoading: boolean
  chartLoading: boolean
  tableLoading: boolean
  treeLoading: boolean
  //errors
  sampleDetailError?: string
  analysesDetailError?: string
  upgmaError?: string
  mltreeError?: string
  multipleAlignmentError?: string
  //abort
  previousAbortController?: AbortController
}

interface OpenComparisonsState {
  tabs: ComparisonTab[]
  selectedTabId: string
  isLoaded: boolean
  showGroupColors: boolean
  zoomValue: number
  isgroupNamesUpdated: boolean
  previousData? : SampleMetaDataDetailListItem[] | undefined
  currentSortedData : SampleMetaDataDetailListItem[] | undefined

  setSelectedTabId: (selectedTabId: string) => void
  setIsLoaded: (isLoaded: boolean) => void
  setShowGroupColors: (showGroupColors: boolean) => void
  setShowZoomValue: (zoomValue: number) => void
  setIsgroupNamesUpdated: (isgroupNamesUpdated: boolean) => void

  //tabs functions
  addTab: (comparison: Comparison, organizationName?: string, unsaved?: boolean, sortOverride?: Sort, samplesLoading?: boolean) => string
  addSamplesToTab: (id: string, addedComparedSamples?: ComparedSample[]) => void
  getTabById: (id: string) => ComparisonTab | undefined
  getTabByIndex: (index: number) => ComparisonTab | undefined
  closeTab: (id: string) => void
  updateTab: (id: string, tab: ComparisonTab) => void
  //tab data functions  
  setCurrentTabName: (name: string) => void
  setTabUnsaved: (id: string, unsaved: boolean) => void
  setUnsaved: (unsaved: boolean) => void
  setTabComparison: (id: string, comparison: Comparison) => void
  setComparison: (comparison: Comparison) => void
  setTabSearchComparisonText: (id: string, searchComparisonText: string) => void
  setSearchComparisonText: (searchComparisonText: string) => void
  setTabViewDisplayed: (id: string, viewDisplayed: ViewDisplayed) => void
  setViewDisplayed: (viewDisplayed: ViewDisplayed) => void
  setTabAnalysesDisplayed: (id: string, analysesDisplayed: string) => void
  setAnalysesDisplayed: (analysesDisplayed: string) => void
  setTabAnalysesData: (id: string, analysesData: ComparisonWithAnalysis) => void
  setAnalysesData: (analysesData: ComparisonWithAnalysis) => void
  setDendrogramRegions: (dendrogramRegions?: ComparisonRegion[]) => void
  addTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm, tagData: TagsData, tagPositions: tagPositonX[]) => void
  removeTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm, id: 'root' | number) => void
  removeAllTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm) => void
  setSelectedIdsDendrogram: (ids: string[]) => void
  setSelectedRegion: (selectedRegion: ComparisonRegion | undefined, previousSelectedRegionID: 'root' | number | undefined) => void
  setMaxTreeHeight: (maxTreeHeight: number | undefined) => void
  setCollapeseZeroLengthNodes: (collapeseZeroLengthNodes: boolean) => void
  setMultipleAlignment: (multipleAlignment: MultipleAlignment) => void
  setMultipleAlignmentData: (multipleAlignmentData: MultipleAlignmentData) => void
  setProcessType: (processType: ProcesstypeList) => void
  setBlockType: (blockType: 'diff' | null) => void
  setSequenceType: (sequenceType: SequenceType) => void
  setIsFastMode: (isFastMode: boolean) => void 
  //comparison view
  setTabSortedColumn: (id: string, sortedColumn: SortedColumn | undefined) => void
  setSortedColumn: (sortedColumn: SortedColumn | undefined) => void
  setSort: (sort: Sort) => void
  setPreviousData: (previousData: SampleMetaDataDetailListItem[] | undefined) => void
  setCurrentSortedData: (currentSortedData: SampleMetaDataDetailListItem[] | undefined) => void
  setIsSortedInDendrogram: (isSortedInDendrogram: boolean) => void
  setEditDataViewerKey: (editDataViewerKey: number) => void
  setNewEdits: (newEdits: string[]) => void
  setShowSingleClickEdit: (showSingleClickEdit: boolean) => void
  setCellEdits: (cellEdits: CellEdits) => void
  setUnselectafterdelete: (unselectafterdelete: boolean) => void
  setTabSampleData: (id: string, sampleData: Sample[]) => void
  setSampleData: (sampleData: Sample[]) => void
  setFilteredSortedSampleIds: (filteredSortedSampleIds: string[]) => void
  setSamplesLoading: (loading: boolean) => void
  setAnalysesLoading: (analysesLoading: boolean) => void
  setChartLoading: (chartLoading: boolean) => void
  setTableLoading: (tableLoading: boolean) => void
  setMltreeLoading: (treeLoading: boolean) => void
  setSampleDetailError: (sampleDetailError: string) => void
  setAnalysesDetailError: (sampleDetailError: string) => void
  setUpgmaError: (sampleDetailError: string) => void
  setMltreeError: (mltreeError: string) => void
  setTabSampleDetailError: (id: string, sampleDetailError: string) => void
  setTabAnalysesDetailError: (id: string, analysesDetailError: string) => void
  setTabUpgmaError: (id: string, upgmaError: string) => void
  setMultipleAlignmentError: (multipleAlignmentError: string) => void
  setTabSamplesLoading: (id: string, samplesLoading: boolean) => void
  setTabAnalysesLoading: (id: string, analysesLoading: boolean) => void
  setTabChartLoading: (id: string, chartLoading: boolean) => void
  isSortContainerShowing: boolean;
  setIsSortContainerShowing: (isSortContainerShowing: boolean) => void
  setAllProcessTypes: (allProcessTypes: string[]) => void
  setPreviousAbortController: (previousAbortController: AbortController) => void
}

export const useOpenComparisonsStore = create<OpenComparisonsState>((set, get) => ({
  tabs: [],
  selectedTabId: "",
  isLoaded: false,
  showGroupColors: false,
  zoomValue: 100,
  isgroupNamesUpdated: false,
  previousData: undefined,
  currentSortedData: undefined,

  setSelectedTabId: (selectedTabId: string) => set({ selectedTabId }),

  setIsLoaded: (isLoaded: boolean) => set({ isLoaded }),
  setShowGroupColors: (showGroupColors: boolean) => set({ showGroupColors }),
  setShowZoomValue: (zoomValue: number) => set({ zoomValue }),
  setIsgroupNamesUpdated: (isgroupNamesUpdated: boolean) => set({ isgroupNamesUpdated }),

  //tabs functions
  addTab: (comparison: Comparison, organizationName?: string, unsaved?: boolean, sortOverride? : Sort, samplesLoading?: boolean) => {
    const newTabId = crypto.randomUUID()
    const defaultSort = useDataViewerCtrStore.getState().sort || {field :"+data.metadata.Key", index: 0, order: "desc"}
    set(state => {
      //if selected samples added or if new comparison set unsaved            
      const newTab = {
        id: newTabId,
        name: comparison.id ? comparison.name : `Draft (${state.tabs.filter(v => !comparison.name).length + 1})`,
        unsaved,
        comparison,
        sort: sortOverride? sortOverride : defaultSort,
        isSortedInDendrogram: false,
        samplesLoading: samplesLoading || false,
        searchComparisonText: "",
        viewDisplayed: "Analyses",
        analysesDisplayed: (organizationName?.toLowerCase().includes('calicinet')) ? 'SEQ_RegB-GI' : "quality",
        multipleAlignment: "Sequences",
        processType: "SEQ_RegB-GI",
        blockType: null,
        sequenceType: "DNA",
        isFastMode: true,
        collapeseZeroLengthNodes: true,
        editDataViewerKey: 0,
        newEdits: [],
        showSingleClickEdit: false,
        cellEdits: {},
        unselectafterdelete: false,
        analysesLoading: true,
        chartLoading: false,
        tableLoading: false,
        treeLoading: false,
        allProcessTypes: []
      } as ComparisonTab;
      return {
        tabs: [...state.tabs, newTab],
        selectedTabId: newTabId
      };
    });
    return newTabId
  },
  addSamplesToTab: (id: string, addedComparedSamples?: ComparedSample[]) => { },
  getTabByIndex: (index: number) => get().tabs[index],
  getTabById: (id: string) => get().tabs.find(v => v.id === id),
  closeTab: (id: string) => {
    set(state => {
      const closedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      const lastTab = closedTabIndex === state.tabs.length - 1
      const newTabs = state.tabs.filter(tab => tab.id !== id)
      return {
        tabs: newTabs,
        selectedTabId: id === state.selectedTabId ? //if closed tab is selected
          lastTab ?
            state.getTabByIndex(newTabs.length - 1)?.id //select next to last tab
            : newTabs[closedTabIndex].id //select next tab
          : state.selectedTabId //keep current selected
      }
    });
  },
  updateTab: (id: string, tab: ComparisonTab) => {
    set(({ tabs }) => ({ tabs: tabs.map((v) => v.id === id ? tab : v) }))
  },
  //tab data functions
  setCurrentTabName: (name: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], name }
      return { tabs }
    })
  },
  setTabUnsaved: (id, unsaved) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], unsaved }
      return { tabs }
    })
  },
  setUnsaved: (unsaved) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], unsaved }
      return { tabs }
    })
  },
  setTabComparison: (id: string, comparison: Comparison) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], comparison }
      return { tabs }
    })
  },
  setComparison: (comparison) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], comparison }
      return { tabs }
    })
  },
  setTabSearchComparisonText: (id: string, searchComparisonText: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], searchComparisonText }
      return { tabs }
    })
  },
  setSearchComparisonText: (searchComparisonText) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], searchComparisonText }
      return { tabs }
    })
  },
  setTabViewDisplayed: (id: string, viewDisplayed: ViewDisplayed) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], viewDisplayed }
      return { tabs }
    })
  },
  setViewDisplayed: (viewDisplayed: ViewDisplayed) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], viewDisplayed }
      return { tabs }
    })
  },
  setTabAnalysesDisplayed: (id: string, analysesDisplayed: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesDisplayed }
      return { tabs }
    })
  },
  setAnalysesDisplayed: (analysesDisplayed: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesDisplayed }
      return { tabs }
    })
  },
  setTabAnalysesData: (id: string, analysesData: ComparisonWithAnalysis) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesData }
      return { tabs }
    })
  },
  setAnalysesData: (analysesData: ComparisonWithAnalysis) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesData }
      return { tabs }
    })
  },
  setDendrogramRegions: (dendrogramRegions?: ComparisonRegion[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], dendrogramRegions }
      return { tabs }
    })
  },
  addTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm, tagData: TagsData, tagPositions: tagPositonX[]) => {
    set((state) => {
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      const tabs = state.tabs.map((tab) => {
        if (tab === state.tabs[selectedTabIndex]) {
          // Check if there's an existing tab for the distance and algorithm
          const existingIndex = tab.tagsData?.findIndex(t => t.distance === distance && t.algorithm === algorithm)
          if (existingIndex !== -1 && existingIndex !== undefined) {
            const updatedTagsData = [...(tab.tagsData || [])]
            let entryToUpdate = updatedTagsData[existingIndex]
            entryToUpdate.data = [...entryToUpdate.data, tagData]
            updatedTagsData[existingIndex] = entryToUpdate
            return { ...tab, tagsData: updatedTagsData, unsaved: true, tagPositions }
          } else {
            const newEntry: ComparisonWithDendrogram_tag = {
              distance,
              algorithm,
              data: [tagData]
            }
            const updatedTagsData = [...(tab.tagsData || []), newEntry];
            return { ...tab, tagsData: updatedTagsData, unsaved: true, tagPositions }
          }
        }
        return tab
      })
      return { tabs }
    })
  },
  removeTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm, id: 'root' | number) => {
    set((state) => {
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      const tabs = state.tabs.map((tab, index) => {
        if (index === selectedTabIndex && tab.tagsData) {
          // Check if there's an existing tab for the distance and algorithm
          const tagDataIndex = tab.tagsData.findIndex(tagData => tagData.distance === distance && tagData.algorithm === algorithm);

          if (tagDataIndex !== -1) {
            const updatedTagsData = [...tab.tagsData];
            const specificTagData = updatedTagsData[tagDataIndex];
            // Filter out the tag data with the specified id.
            specificTagData.data = specificTagData.data.filter(tag => tag.region.id !== id);

            // If after removal, the data array is empty, remove the entire tag data entry.
            if (specificTagData.data.length === 0) {
              updatedTagsData.splice(tagDataIndex, 1);
            } else {
              // Otherwise, update the tag data entry with the filtered data.
              updatedTagsData[tagDataIndex] = specificTagData;
            }

            const updatedTagPositions = tab.tagPositions
            ? tab.tagPositions.filter(position => position.id !== id)
            : [];

            return { ...tab, tagsData: updatedTagsData, unsaved: true, tagPositions: updatedTagPositions };
          }
        }
        return tab;
      });

      return { tabs };
    });
  },
  removeAllTagData: (distance: DendrogramDistance, algorithm: DendrogramAlgorythm) => {
    set((state) => {
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      const tabs = state.tabs.map((tab, index) => {
        if (index === selectedTabIndex && tab.tagsData) {
          // Check if there's an existing tab for the distance and algorithm
          const tagDataIndex = tab.tagsData.findIndex(tagData => tagData.distance === distance && tagData.algorithm === algorithm);

          if (tagDataIndex !== -1) {
            const updatedTagsData = [...tab.tagsData];
            updatedTagsData.splice(tagDataIndex, 1);
            return { ...tab, tagsData: updatedTagsData, unsaved: true, tagPositions: [] };
          }
        }
        return tab;
      });

      return { tabs };
    });
  },
  setSelectedIdsDendrogram: (ids: string[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], selectedIdsDendrogram: ids }
      return { tabs }
    })
  },
  setSelectedRegion: (selectedRegion: ComparisonRegion | undefined, previousSelectedRegionID: 'root' | number | undefined) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], selectedRegion, previousSelectedRegionID }
      return { tabs }
    })
  },
  setMaxTreeHeight: (maxTreeHeight: number | undefined) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], maxTreeHeight }
      return { tabs }
    })
  },
  setCollapeseZeroLengthNodes: (collapeseZeroLengthNodes: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], collapeseZeroLengthNodes }
      return { tabs }
    })
  },
  setMultipleAlignment: (multipleAlignment: MultipleAlignment) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], multipleAlignment: multipleAlignment }
      return { tabs }
    })
  },
  setMultipleAlignmentData: (multipleAlignmentData: MultipleAlignmentData) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], multipleAlignmentData }
      return { tabs }
    })
  },
  setProcessType: (processType: ProcesstypeList) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], processType }
      return { tabs }
    })
  },
  setBlockType: (blockType: 'diff' | null) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], blockType }
      return { tabs }
    })
  },
  setSequenceType: (sequenceType: SequenceType) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sequenceType }
      return { tabs }
    })
  },
  setIsFastMode: (isFastMode: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], isFastMode }
      return { tabs }
    })
  },
  //comparison view
  setTabSortedColumn: (id: string, sortedColumn: SortedColumn | undefined) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sortedColumn }
      return { tabs }
    })
  },
  setSortedColumn: (sortedColumn: SortedColumn | undefined) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sortedColumn }
      return { tabs }
    })
  },
  setSort: (sort: Sort) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sort }
      return { tabs }
    })
  },
  setPreviousData: (previousData: SampleMetaDataDetailListItem[] | undefined) => set({ previousData }),
  setCurrentSortedData: (currentSortedData: SampleMetaDataDetailListItem[] | undefined) => set({currentSortedData}),
  setIsSortedInDendrogram: (isSortedInDendrogram: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], isSortedInDendrogram }
      return { tabs }
    })
  },
  setEditDataViewerKey: (editDataViewerKey: number) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], editDataViewerKey }
      return { tabs }
    })
  },
  setNewEdits: (newEdits: string[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], newEdits }
      return { tabs }
    })
  },
  setShowSingleClickEdit: (showSingleClickEdit: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], showSingleClickEdit }
      return { tabs }
    })
  },
  setCellEdits: (cellEdits: CellEdits) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], cellEdits }
      return { tabs }
    })
  },
  setUnselectafterdelete: (unselectafterdelete: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], unselectafterdelete }
      return { tabs }
    })
  },
  setTabSampleData: (id: string, sampleData: Sample[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sampleData }
      return { tabs }
    })
  },
  setSampleData: (sampleData: Sample[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sampleData }
      return { tabs }
    })
  },
  setFilteredSortedSampleIds: (filteredSortedSampleIds: string[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], filteredSortedSampleIds }
      return { tabs }
    })
  },
  setTabSampleDetailError: (id: string, sampleDetailError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sampleDetailError }
      return { tabs }
    })
  },
  setSampleDetailError: (sampleDetailError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], sampleDetailError }
      return { tabs }
    })
  },
  setTabAnalysesDetailError: (id: string, analysesDetailError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesDetailError }
      return { tabs }
    })
  },
  setAnalysesDetailError: (analysesDetailError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesDetailError }
      return { tabs }
    })
  },
  setTabUpgmaError: (id: string, upgmaError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], upgmaError }
      return { tabs }
    })
  },
  setUpgmaError: (upgmaError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], upgmaError }
      return { tabs }
    })
  },
  setMltreeError: (mltreeError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], mltreeError }
      return { tabs }
    })
  },
  setMultipleAlignmentError: (multipleAlignmentError: string) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], multipleAlignmentError }
      return { tabs }
    })
  },
  setTabSamplesLoading: (id: string, samplesLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], samplesLoading }
      return { tabs }
    })
  },
  setSamplesLoading: (samplesLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], samplesLoading }
      return { tabs }
    })
  },
  setTabAnalysesLoading: (id: string, analysesLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesLoading }
      return { tabs }
    })
  },
  setAnalysesLoading: (analysesLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], analysesLoading }
      return { tabs }
    })
  },
  setTabChartLoading: (id: string, chartLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(id)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], chartLoading }
      return { tabs }
    })
  },
  setChartLoading: (chartLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], chartLoading }
      return { tabs }
    })
  },
  isSortContainerShowing: false,
  setIsSortContainerShowing: (isSortContainerShowing: boolean) => set({ isSortContainerShowing }),
  setTableLoading: (tableLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], tableLoading }
      return { tabs }
    })
  },
  setMltreeLoading: (treeLoading: boolean) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], treeLoading }
      return { tabs }
    })
  },
  setAllProcessTypes: (allProcessTypes: string[]) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], allProcessTypes }
      return { tabs }
    })
  },
  setPreviousAbortController: (previousAbortController: AbortController) => {
    set((state) => {
      const tabs = [...state.tabs]
      const selectedTabIndex = state.tabs.map(v => v.id).indexOf(state.selectedTabId)
      tabs[selectedTabIndex] = { ...tabs[selectedTabIndex], previousAbortController }
      return { tabs }
    })
  },
}));