import { Card } from "@blueprintjs/core/lib/esm/components/card/card";
import { Elevation } from "@blueprintjs/core/lib/esm/common/elevation";
import { H3, H4 } from "@blueprintjs/core/lib/esm/components/html/html";
import { Icon } from "@blueprintjs/core/lib/esm/components/icon/icon";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { FileSelectBaseSpace } from "./FileSelectBaseSpace";
import { FileSelectNCBISRA } from "./FileSelectNCIBSRA";
import { Colors } from "@blueprintjs/core";
import { handleMenuItemKeyPress } from "../../services/508Accessibility";
import { useStartPipelineDialogStore } from "../../stores/StartPipelineDialogStore";
import { FileType } from "./StartPipelineDialog";

interface SelectFilesStepProps {
  isCN?: boolean
}

export const SelectFilesStep = ({ isCN }: SelectFilesStepProps) => {
  const { source, setSource, selectedFiles, setSelectedFiles, addSelectedFiles, removeFile, setTotalSelectedSamples } = useStartPipelineDialogStore()

  const showSelectSourcePanel = () => <>
    <H3>Select a File Source</H3>
    <div style={{ display: 'flex', flexDirection: "column", gap: '20px' }}>
      <Card tabIndex={0} onKeyDown={(e) => handleMenuItemKeyPress(e, () => setSource('local'))} interactive={true} elevation={Elevation.TWO} onClick={() => setSource("local")}>
        <H4><Icon icon="import"></Icon> Local Storage</H4>
        <p>
          Upload sample files from local storage, network storage or shared drive.
        </p>
      </Card>
      <Card tabIndex={0} onKeyDown={(e) => handleMenuItemKeyPress(e, () => setSource('basespace'))} interactive={true} elevation={Elevation.TWO} onClick={() => setSource("basespace")}>
        <H4><Icon icon="globe-network"></Icon> BaseSpace</H4>
        <p>
          Provide BaseSpace user crendentials to select projects and associated sample files.
        </p>
      </Card>
      {!isCN && 
      <Card tabIndex={0} onKeyDown={(e) => handleMenuItemKeyPress(e, () => setSource('sra'))} interactive={true} elevation={Elevation.TWO} onClick={() => setSource("sra")}>
        <H4><Icon icon="globe-network"></Icon> NCBI SRA</H4>
        <p>
          Search NCBI Sequence Read Archive (SRA) and select listed sample files.
        </p>
      </Card>
      }
    </div>
  </>

  const fileInput_onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      // setTrackedSamples(convertFilesToTrackedSamples(event.target.files))
      addSelectedFiles(Array.from(event.target.files).map(file => {
        const extension = file.name.split(".")[1].toLowerCase();
        let fileType: FileType = "paired"
        if (extension === "fasta") {
          fileType = "assembly"
        } else if (extension === "ab1" || extension === "scf") {
          fileType = "sanger"
        }
        return { 
          name: file.name, 
          source: "local", 
          file, 
          fileProgress: 0, 
          uploaded: false, 
          fileType 
        }
      }))
    }
  }

  const hasMissingFiles = (): boolean => {
    let numPairedFiles = 0;
    selectedFiles.forEach(file => {
      if (file.fileType === "paired")
        numPairedFiles++
    })
    return numPairedFiles % 2 !== 0
  }

  const localSource = <>
    <input type="file" multiple onChange={fileInput_onChange} style={{ display: 'none' }} id="fileInput"/>
    <Button icon="upload" text="Select Files" onClick={() => document.getElementById('fileInput')?.click()} />
    {selectedFiles.length
      ? <div style={{ display: 'flex', flexDirection: "column", gap: '5px', height: "400px", overflowY: "auto", padding: "5px" }}>
        {selectedFiles.map((v, i) => <Card key={i} elevation={Elevation.ONE} style={{ padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Icon icon="document"></Icon>
            {v.name}
          </div>
          <Button icon="cross" minimal onClick={() => removeFile(v.name)} />
        </Card>)}
        {hasMissingFiles() ? <div style={{ display: "flex", gap: 5 }}>
          <Icon icon="warning-sign" intent="danger"></Icon>
          <div style={{ color: Colors.RED1 }}> Unpaired file found. Please select 2 paired read files for each sample.</div>
        </div> : null}
      </div>
      : <div>No files selected.</div>}
  </>


  const showSourcePanel = () => {
    let panel
    if (isCN) {
      switch (source) {
        case "local": panel = localSource
          break;
        case "basespace": panel = <FileSelectBaseSpace />
          break;
      }
    }
    else {
      switch (source) {
        case "local": panel = localSource
          break;
        case "basespace": panel = <FileSelectBaseSpace />
          break;
        case "sra": panel = <FileSelectNCBISRA />
          break;
      }
    }
    return <div>
      {panel}
      <Button onClick={() => {
        setSource(undefined)
        setSelectedFiles([])
        setTotalSelectedSamples([])
      }}>Back to File Source Select</Button>
    </div>
  }

  return source ? showSourcePanel() : showSelectSourcePanel()


}