import { useState, useEffect } from "react";
import { Button } from "@blueprintjs/core/lib/esm/components/button/buttons";
import { Popover2 } from "@blueprintjs/popover2/lib/esm/popover2";
import { useUserStore } from '../stores/UserStore';
import { useAuth } from "react-oidc-context";
import { LabAPI, UserAPI } from "../api/DataAPI";
import { Select2 } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core/lib/esm/components/menu/menuItem";
import cloneDeep from "lodash.clonedeep";
import { Toaster } from "../utils/Toaster";

export function UserProfilePopover() {
    const auth = useAuth();
    const { user, setLoggedOut } = useUserStore()
    const [labIdList, setLabIdList] = useState<string[]>([])

    const isRoleDataBaseManager = user && user.role.includes("DatabaseManager") ? true : false

    useEffect(() => {
        if (isRoleDataBaseManager) {
            const fetchLabs = async () => {
                try {
                    const labAPI = new LabAPI(auth.user?.access_token ?? "")
                    const labs = await labAPI.getAll()
                    const uniqueLabIds = Array.from(new Set(labs.map(lab => lab.labId)))
                    setLabIdList(uniqueLabIds)
                } catch (e) {
                    throw (e)
                }
            };
            fetchLabs()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRoleDataBaseManager])

    const logOutButton_onClick = () => {
        setLoggedOut(true)
        auth.removeUser();
        window.location.replace("https://auth.cdc.gov/errors/globallogout.asp");
    }

    const copyAPIToken_onClick = () => {
        var text = auth.user?.access_token ?? "";
        if (navigator.clipboard) { 
            navigator.clipboard.writeText(text).then(function() { 
                // Optional: Inform the user that the text has been copied 
                alert("Text copied to clipboard!"); 
            }).catch(function(err) { 
                console.error('Could not copy text: ', err); 
            }); } 
        else {
            // Create a temporary text area 
            var textArea = document.createElement("textarea"); 
            textArea.value = text; document.body.appendChild(textArea); 
            // Select the text area 
            textArea.select(); 
            textArea.setSelectionRange(0, 99999); // For mobile devices 
            // Copy the text 
            document.execCommand("copy"); 
            // Remove the temporary text area 
            document.body.removeChild(textArea); // Optional: Inform the user that the text has been copied alert("Text copied to clipboard!");
        }        
    }

    const updateUserLabId = async (selectedLabId: string) => {
        if (user) {
            try {
                const userAPI = new UserAPI(auth.user?.access_token ?? "");
                const updatedUser = cloneDeep(user)
                updatedUser.labId = selectedLabId
                await userAPI.update(user.id, updatedUser)
                window.location.reload()
            } catch (e) {
                Toaster.show({ icon: "error", message: `Failed to update the LabId`, intent: "danger" })
                throw (e)
            }
        }
    }

    return (user ? <Popover2
        content={
            <div style={{ padding: 10, display: "flex", flexDirection: "column", gap: 5 }}>
                <div><strong>User:</strong> {user.name} ({user.email})</div>                
                <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <strong>LabId:</strong>
                    {isRoleDataBaseManager && labIdList.length > 0 ?
                        <Select2                            
                            onItemSelect={updateUserLabId}                            
                            items={labIdList}
                            filterable={false}
                            itemRenderer={(labId, { handleClick, handleFocus, index }) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={handleClick}
                                        onFocus={handleFocus}
                                        roleStructure="listoption"
                                        text={labId}
                                    />
                                );
                            }}
                        >
                            <Button aria-label="open/close lab selection options" text={user.labId || "Select Lab"} rightIcon="caret-down" />
                        </Select2>
                        : user.labId}</div>
                <div><strong>Role:</strong> {user.role.join(", ")}</div>
                <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <Button minimal aria-label="copy api token to clipboard" icon="clipboard" onClick={copyAPIToken_onClick}>Copy API Token</Button>
                    <Button minimal aria-label="logout of your profile" icon="log-out" onClick={logOutButton_onClick}>Log Out</Button>
                </div>
            </div>
        }
        interactionKind="click"
        placement="bottom-start"        
    >
        <Button aria-label="open/close user profile menu" icon="user" minimal data-testid="userProfileButton" text={user.name} />
    </Popover2 > : null
    );
}