import { useOrganismStore } from "../stores/OrganismStore"
import { useUserStore } from "../stores/UserStore"
import { FieldAccessType, FieldProps } from "../types/OrganismTypes"

interface OrganismFunctions {
  getViewableFields: (nationalDatabase: boolean) => {[key: string]: FieldProps}
  getEditableFields: (nationalDatabase: boolean, published?: boolean) => {[key: string]: FieldProps}
  canViewField: (access: FieldAccessType, nationalDatabase: boolean) => boolean
  canEditField: (access: FieldAccessType, nationalDatabase: boolean, published?: boolean) => boolean
}

export const useOrganismFunctions = (): OrganismFunctions => {  
  const { organism } = useOrganismStore()  
  const { user } = useUserStore()      

  const isReadOnlyUser = () => user?.role.includes("ReadOnly") ?? false
  const isUserLabUser = () => user?.role.includes("LabUser") ?? false
  const isUserDBM = () => user?.role.includes("DatabaseManager") ?? false

  const canViewField = (access: FieldAccessType, nationalDatabase: boolean) => {
    switch(access){
      case "lab_only_field": return !nationalDatabase && (isUserLabUser() || isReadOnlyUser())
      case "lab_field": return true
      case "ndb_field": return true
      case "dbm_field": return nationalDatabase && isUserDBM()
      case "internal": return true
      default: return false
    }
  }

  const canEditField = (access: FieldAccessType, nationalDatabase: boolean, published?: boolean) => {
    if (isReadOnlyUser())
      return false;
    switch(access){
      case "lab_only_field": return !nationalDatabase && isUserLabUser()
      case "lab_field": return (!nationalDatabase && !published) || (isUserDBM() && nationalDatabase)       
      case "ndb_field": 
      case "dbm_field": return isUserDBM() && nationalDatabase
      default: return false
    }
  }

  const getViewableFields = (nationalDatabase: boolean) => {
    if (!organism)
      return {}
    const entries = Object.entries(organism?.properties)
    const filteredEntries = entries.filter(([k,v]) => canViewField(v.access ?? "internal", nationalDatabase))
    return Object.fromEntries(filteredEntries)
  }

  const getEditableFields = (nationalDatabase: boolean, published?: boolean) => {
    if (!organism)
      return {}
    const entries = Object.entries(organism?.properties)
    const filteredEntries = entries.filter(([k,v]) => canEditField(v.access ?? "internal", nationalDatabase, published))
    return Object.fromEntries(filteredEntries)
  }

  return { getViewableFields, getEditableFields, canViewField, canEditField}
}