import jsonLogic from 'json-logic-js';
import { Fields } from './types/OrganismTypes';

// export interface Fields {
//   required: string[],
//   properties: {
//     [key: string]: FieldProps
//   }
// }

// export interface FieldProps {
//   type: string
//   title?: string
//   category?: string
//   description?: string
//   examples?: any[]
//   enum?: string[]
//   pattern?: string
//   max?: number | string
//   min?: number | string
//   path?: string
//   freeEntry?: boolean
//   invalidIf?: {
//     message: string,
//     rule: jsonLogic.RulesLogic<any>
//   }
//   requiredInViews?: boolean
//   identifier?: boolean
//   access?: string
// }

export let userFields = {
  required: [
    "name",
    "labId",
    "userId",
  ],
  properties: {
    name:{
      title: "Name",
      description: "the user's name",
      type: "text",
      examples: ["John Doe"],
      category: "General",
      path: "name"
    },
    userId: {
      title: "User ID",
      description: "the user's ID",
      type: "text",
      examples: ["ABCD@cdc.gov"],
      category: "General",
      path: "userId"
    },
    email: {
      title: "Email",
      description: "the user's Email",
      type: "text",
      examples: ["ABCD@cdc.gov"],
      category: "General",
      path: "email"
    },
    labId: {
      title: "Lab ID",
      description: "the user's lab ID",
      type: "select2",
      enum: ['test1', 'test2'],
      examples: ["LAB ABC"],
      category: "General",
      path: "labId"
    },
    role: {
      title: "Role",
      description: "the user's role",
      type: "multiselect",
      enum:['ReadOnly', 'LabUser' , 'DatabaseManager' , 'Admin',],
      examples: ["Labuser", "DatabaseManager"],
      category: "General",
      path: "role"
    },
    organization: {
      title: "Organization",
      description: "the user's role",
      type: "multiselect",
      enum:['CaliciNet', 'PulseNet'],      
      category: "General",
      path: "organization"
    },
    createdAt: {
      title: "Created At",
      description: "date user was created",
      type: "date",
      pattern: "^\\d{4}-\\d{1,2}-\\d{1,2}$",
      examples: ["yyyy-mm-dd"],
      category: "General",
      path: "createdAt",
      max: "$today"
    },
    createdBy: {
      title: "Created By",
      description: "the name of the entity that created this user",
      type: "text",
      examples: ["SAMSACCOUNT"],
      category: "General",
      path: "createdBy"
    },
    updatedAt: {
      title: "Updated At",
      description: "the date this user's data was updated",
      type: "date",
      pattern: "^\\d{4}-\\d{1,2}-\\d{1,2}$",
      examples: ["yyyy-mm-dd"],
      category: "General",
      path: "updatedAt",
      max: "$today"
    },
    updatedBy: {
      title: "Updated By",
      description: "The user ID of the last user to update this user",
      type: "text",
      examples: ["ABCD@cdc.gov"],
      category: "General",
      path: "updatedBy"
    }
  }
} as Fields

export let labFields = {
  required: [
    "name",
    "labId"
  ],
  properties: {
    name:{
      title: "Name",
      description: "the user's name",
      type: "text",
      examples: ["John Doe"],
      category: "General",
      path: "name"
    },
    labId: {
      title: "Lab ID",
      description: "the user's lab ID",
      type: "text",
      examples: ["LAB ABC"],
      category: "General",
      path: "labId"
    },
    createdAt: {
      title: "Created At",
      description: "date user was created",
      type: "date",
      pattern: "^\\d{4}-\\d{1,2}-\\d{1,2}$",
      examples: ["yyyy-mm-dd"],
      category: "General",
      path: "createdAt",
      max: "$today"
    },
    createdBy: {
      title: "Created By",
      description: "the name of the entity that created this user",
      type: "text",
      examples: ["SAMSACCOUNT"],
      category: "General",
      path: "createdBy"
    },
    updatedAt: {
      title: "Updated At",
      description: "the date this user's data was updated",
      type: "date",
      pattern: "^\\d{4}-\\d{1,2}-\\d{1,2}$",
      examples: ["yyyy-mm-dd"],
      category: "General",
      path: "updatedAt",
      max: "$today"
    },
    updatedBy: {
      title: "Updated By",
      description: "The user ID of the last user to update this user",
      type: "text",
      examples: ["ABCD@cdc.gov"],
      category: "General",
      path: "updatedBy"
    }
  }
} as Fields

export let genoTypes = ["Nov GI", "Nov GII", "AsV", "SaV", "RoV"]